<template>
  <footer class="footer">
    <div class="footer-content">
      <div class="footer-sections">
        <div class="footer-section">
          <h3>关于我们</h3>
          <ul>
            <li><a href="https://we.emoera.com" target="_blank" rel="noopener noreferrer">E时代官网</a></li>
            <li><el-icon><Link /></el-icon><a href="https://qifalab.cn" target="_blank" rel="noopener noreferrer">启发实验室</a></li>
          </ul>
        </div>

        <div class="footer-section">
          <h3>产品服务</h3>
          <ul>
            <li><a href="https://aboutaccount.emoera.com" target="_blank" rel="noopener noreferrer">E时代通行证</a></li>
            <li><a href="https://ide.emoera.com" target="_blank" rel="noopener noreferrer">E时代IDE</a></li>
          </ul>
        </div>

        <div class="footer-section contact-section">
          <h3>联系方式</h3>
          <div class="contact-info">
            <ul>
            <li><el-icon><Message /></el-icon> <a href="mailto:account@qifalab.com">account@qifalab.com</a></li>
            <li><el-icon><Document /></el-icon> <a href="https://docs.qq.com/aio/DVHZpRFFTdUVIYlV2?p=kX3caDB2UwIVKSlijfdD7X" target="_blank" rel="noopener noreferrer">此项目更新日志</a></li>
            </ul>
          </div>
        </div>
      </div>

      <div class="footer-divider"></div>

      <div class="footer-bottom">
        <div class="copyright">
          <span>© {{ currentYear }} 启发lab 版权所有</span>
          <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener">蜀ICP备2024055741号</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { Location, Phone, Message } from '@element-plus/icons-vue'

const currentYear = ref(new Date().getFullYear())
</script>

<style scoped>
.footer {
  background-color: #f5f7fa;
  padding: 48px 0 24px;
  color: var(--el-text-color-regular);
  font-size: 14px;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-sections {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
}

.footer-section h3 {
  font-size: 16px;
  font-weight: 500;
  color: var(--el-text-color-primary);
  margin-bottom: 20px;
}

.footer-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-section ul li {
  margin-bottom: 12px;
}

.footer-section ul li a {
  color: var(--el-text-color-regular);
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-section ul li a:hover {
  color: var(--el-color-primary);
}

.contact-info p {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
}

.footer-divider {
  height: 1px;
  background-color: var(--el-border-color-lighter);
  margin: 32px 0 24px;
}

.copyright {
  display: flex;
  justify-content: center;
  gap: 20px;
  font-size: 12px;
}

.copyright a {
  color: var(--el-text-color-regular);
  text-decoration: none;
}

.copyright a:hover {
  color: var(--el-color-primary);
}

@media screen and (max-width: 768px) {
  .footer {
    padding: 32px 0 20px;
  }

  .footer-sections {
    grid-template-columns: 1fr;
    gap: 24px;
  }

  .copyright {
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
}
</style> 