import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, unref as _unref, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "footer" }
const _hoisted_2 = { class: "footer-content" }
const _hoisted_3 = { class: "footer-sections" }
const _hoisted_4 = { class: "footer-section" }
const _hoisted_5 = { class: "footer-section contact-section" }
const _hoisted_6 = { class: "contact-info" }
const _hoisted_7 = { class: "footer-bottom" }
const _hoisted_8 = { class: "copyright" }

import { ref } from 'vue'
import { Location, Phone, Message } from '@element-plus/icons-vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'FooterComponent',
  setup(__props) {

const currentYear = ref(new Date().getFullYear())

return (_ctx: any,_cache: any) => {
  const _component_Link = _resolveComponent("Link")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_Document = _resolveComponent("Document")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[2] || (_cache[2] = _createElementVNode("h3", null, "关于我们", -1)),
          _createElementVNode("ul", null, [
            _cache[1] || (_cache[1] = _createElementVNode("li", null, [
              _createElementVNode("a", {
                href: "https://we.emoera.com",
                target: "_blank",
                rel: "noopener noreferrer"
              }, "E时代官网")
            ], -1)),
            _createElementVNode("li", null, [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Link)
                ]),
                _: 1
              }),
              _cache[0] || (_cache[0] = _createElementVNode("a", {
                href: "https://qifalab.cn",
                target: "_blank",
                rel: "noopener noreferrer"
              }, "启发实验室", -1))
            ])
          ])
        ]),
        _cache[8] || (_cache[8] = _createElementVNode("div", { class: "footer-section" }, [
          _createElementVNode("h3", null, "产品服务"),
          _createElementVNode("ul", null, [
            _createElementVNode("li", null, [
              _createElementVNode("a", {
                href: "https://aboutaccount.emoera.com",
                target: "_blank",
                rel: "noopener noreferrer"
              }, "E时代通行证")
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("a", {
                href: "https://ide.emoera.com",
                target: "_blank",
                rel: "noopener noreferrer"
              }, "E时代IDE")
            ])
          ])
        ], -1)),
        _createElementVNode("div", _hoisted_5, [
          _cache[7] || (_cache[7] = _createElementVNode("h3", null, "联系方式", -1)),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("ul", null, [
              _createElementVNode("li", null, [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_unref(Message))
                  ]),
                  _: 1
                }),
                _cache[3] || (_cache[3] = _createTextVNode()),
                _cache[4] || (_cache[4] = _createElementVNode("a", { href: "mailto:account@qifalab.com" }, "account@qifalab.com", -1))
              ]),
              _createElementVNode("li", null, [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_Document)
                  ]),
                  _: 1
                }),
                _cache[5] || (_cache[5] = _createTextVNode()),
                _cache[6] || (_cache[6] = _createElementVNode("a", {
                  href: "https://docs.qq.com/aio/DVHZpRFFTdUVIYlV2?p=kX3caDB2UwIVKSlijfdD7X",
                  target: "_blank",
                  rel: "noopener noreferrer"
                }, "此项目更新日志", -1))
              ])
            ])
          ])
        ])
      ]),
      _cache[10] || (_cache[10] = _createElementVNode("div", { class: "footer-divider" }, null, -1)),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("span", null, "© " + _toDisplayString(currentYear.value) + " 启发lab 版权所有", 1),
          _cache[9] || (_cache[9] = _createElementVNode("a", {
            href: "https://beian.miit.gov.cn/",
            target: "_blank",
            rel: "noopener"
          }, "蜀ICP备2024055741号", -1))
        ])
      ])
    ])
  ]))
}
}

})