import LoginView from '@/views/LoginView.vue'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { useUserStore } from '@/store/user'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { 
      requiresAuth: true,
      title: 'E时代通行证 - 首页'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
    meta: {
      title: 'E时代通行证 - 关于我们'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      title: 'E时代通行证 - 登录'
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/RegisterView.vue'),
    meta: {
      title: 'E时代通行证 - 注册'
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/ProfileView.vue'),
    meta: { 
      requiresAuth: true,
      title: 'E时代通行证 - 个人中心'
    }
  },
  {
    path: '/oauth/qq/callback',
    name: 'qq-callback',
    component: () => import('../views/oauth/QQCallback.vue'),
    meta: {
      title: 'QQ登录回调'
    }
  },
  {
    path: '/oauth/authorize',
    name: 'oauth-authorize',
    component: () => import('../views/oauth/AuthorizeView.vue'),
    meta: { 
      requiresAuth: true,
      title: 'E时代通行证 - 授权'
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title as string
  }

  const userStore = useUserStore()
  
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!userStore.token) {
      next({
        path: '/login',
        query: { 
          redirect: to.path === '/login' ? '/' : to.fullPath 
        }
      })
    } else {
      next()
    }
  } else {
    if (to.path === '/login' && userStore.token) {
      const redirect = to.query.redirect as string
      next(redirect || '/')
    } else {
      next()
    }
  }
})

export default router
