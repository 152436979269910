import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/elogo.jpg'


const _hoisted_1 = { class: "left-section" }
const _hoisted_2 = { class: "right-section" }

import { useUserStore } from '@/store/user'
import { useRouter, useRoute } from 'vue-router'
import {nextTick, ref, watch} from 'vue'
import { Loading, CircleCheck, CircleClose, Lock } from '@element-plus/icons-vue'
import { ElMessageBox } from 'element-plus'
import FooterComponent from '@/components/FooterComponent.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const userStore = useUserStore()
const router = useRouter()
const route = useRoute()

const showNav = ref(true)
const securityStatus = ref('checking') // 'checking' | 'secure' | 'insecure'
const showSecurityBadge = ref(false)

// 监听路由变化
watch(() => route.path, (newPath) => {
  if (newPath === '/login') {
    showNav.value = false
  } else {
    showNav.value = true
    showSecurityBadge.value = true
    nextTick(()=>checkSecurity())
  }
}, { immediate: true })

const checkSecurity = () => {
  // 检查当前协议是否为 HTTPS
  const isHttps = window.location.protocol === 'https:'
  
  setTimeout(() => {
    securityStatus.value = isHttps ? 'secure' : 'insecure'
  }, 3000)
}

const handleAnimationComplete = () => {
  if (route.path === '/login') {
    showNav.value = true
  }
  // 动画完成后显示安全标签并开始检查
  showSecurityBadge.value = true
  checkSecurity()
}

const handleLogout = () => {
  userStore.logout()
  router.push('/login')
}

const showSecurityInfo = () => {
  if (securityStatus.value === 'insecure') {
    ElMessageBox.confirm(
      '当前未使用加密连接，可能存在安全风险，是否切换到加密连接？',
      '安全提示',
      {
        confirmButtonText: '切换',
        cancelButtonText: '取消',
        type: 'warning'
      }
    ).then(() => {
      // 切换到 HTTPS
      window.location.href = window.location.href.replace('http:', 'https:')
    }).catch(() => {/* 用户取消操作 */})
  }
}

return (_ctx: any,_cache: any) => {
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["nav-container", { 'show': showNav.value }])
    }, [
      _createVNode(_component_el_menu, {
        router: true,
        mode: "horizontal",
        class: "nav-menu"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _cache[0] || (_cache[0] = _createElementVNode("img", {
              src: _imports_0,
              alt: "Logo",
              class: "logo"
            }, null, -1)),
            _cache[1] || (_cache[1] = _createElementVNode("span", { class: "app-name" }, "E时代通行证", -1)),
            (showSecurityBadge.value)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["security-badge", securityStatus.value]),
                  onClick: showSecurityInfo,
                  style: _normalizeStyle({ cursor: securityStatus.value === 'insecure' ? 'pointer' : 'default' })
                }, [
                  (securityStatus.value === 'checking')
                    ? (_openBlock(), _createBlock(_component_el_icon, { key: 0 }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(Loading))
                        ]),
                        _: 1
                      }))
                    : (securityStatus.value === 'secure')
                      ? (_openBlock(), _createBlock(_component_el_icon, { key: 1 }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(Lock))
                          ]),
                          _: 1
                        }))
                      : (_openBlock(), _createBlock(_component_el_icon, { key: 2 }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(CircleClose))
                          ]),
                          _: 1
                        })),
                  _createElementVNode("span", null, _toDisplayString(securityStatus.value === 'checking' ? '检测中' : 
            securityStatus.value === 'secure' ? '' : '不安全'), 1)
                ], 6))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_2, [
            (_unref(userStore).token)
              ? (_openBlock(), _createBlock(_component_el_button, {
                  key: 0,
                  type: "text",
                  class: "logout-button",
                  onClick: handleLogout
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode(" 退出登录 ")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      })
    ], 2),
    _createVNode(_component_router_view, { onAnimationComplete: handleAnimationComplete }),
    _createVNode(FooterComponent)
  ], 64))
}
}

})