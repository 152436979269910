import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { Icon } from 'tdesign-vue-next'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import { config } from './config'

const app = createApp(App)

// 注册所有Element Plus图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.use(ElementPlus)
app.use(Icon)
app.use(createPinia())
app.use(router)

// 动态添加QQ登录SDK
const addQQSDK = () => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.charset = 'utf-8';
  script.src = 'https://connect.qq.com/qc_jssdk.js';
  script.setAttribute('data-appid', config.qq.appId);
  script.setAttribute('data-redirecturi', config.qq.redirectUri);
  document.head.appendChild(script);
};

addQQSDK();

app.mount('#app')
