<template>
  <div class="nav-container" :class="{ 'show': showNav }">
    <el-menu
      :router="true"
      mode="horizontal"
      class="nav-menu"
    >
      <div class="left-section">
        <img src="@/assets/elogo.jpg" alt="Logo" class="logo">
        <span class="app-name">E时代通行证</span>
        <div 
          v-if="showSecurityBadge" 
          class="security-badge"
          :class="securityStatus"
          @click="showSecurityInfo"
          :style="{ cursor: securityStatus === 'insecure' ? 'pointer' : 'default' }"
        >
          <el-icon v-if="securityStatus === 'checking'"><Loading /></el-icon>
          <el-icon v-else-if="securityStatus === 'secure'"><Lock /></el-icon>
          <el-icon v-else><CircleClose /></el-icon>
          <span>{{ 
            securityStatus === 'checking' ? '检测中' : 
            securityStatus === 'secure' ? '' : '不安全'
          }}</span>
        </div>
      </div>
      
      <div class="right-section">
        <template v-if="userStore.token">
          <el-button 
            type="text" 
            class="logout-button"
            @click="handleLogout"
          >
            退出登录
          </el-button>
        </template>
      </div>
    </el-menu>
  </div>

  <router-view @animation-complete="handleAnimationComplete"/>
  <footer-component />
</template>

<script setup lang="ts">
import { useUserStore } from '@/store/user'
import { useRouter, useRoute } from 'vue-router'
import {nextTick, ref, watch} from 'vue'
import { Loading, CircleCheck, CircleClose, Lock } from '@element-plus/icons-vue'
import { ElMessageBox } from 'element-plus'
import FooterComponent from '@/components/FooterComponent.vue'

const userStore = useUserStore()
const router = useRouter()
const route = useRoute()

const showNav = ref(true)
const securityStatus = ref('checking') // 'checking' | 'secure' | 'insecure'
const showSecurityBadge = ref(false)

// 监听路由变化
watch(() => route.path, (newPath) => {
  if (newPath === '/login') {
    showNav.value = false
  } else {
    showNav.value = true
    showSecurityBadge.value = true
    nextTick(()=>checkSecurity())
  }
}, { immediate: true })

const checkSecurity = () => {
  // 检查当前协议是否为 HTTPS
  const isHttps = window.location.protocol === 'https:'
  
  setTimeout(() => {
    securityStatus.value = isHttps ? 'secure' : 'insecure'
  }, 3000)
}

const handleAnimationComplete = () => {
  if (route.path === '/login') {
    showNav.value = true
  }
  // 动画完成后显示安全标签并开始检查
  showSecurityBadge.value = true
  checkSecurity()
}

const handleLogout = () => {
  userStore.logout()
  router.push('/login')
}

const showSecurityInfo = () => {
  if (securityStatus.value === 'insecure') {
    ElMessageBox.confirm(
      '当前未使用加密连接，可能存在安全风险，是否切换到加密连接？',
      '安全提示',
      {
        confirmButtonText: '切换',
        cancelButtonText: '取消',
        type: 'warning'
      }
    ).then(() => {
      // 切换到 HTTPS
      window.location.href = window.location.href.replace('http:', 'https:')
    }).catch(() => {/* 用户取消操作 */})
  }
}
</script>

<style scoped>
.nav-container {
  border-bottom: solid 1px var(--el-border-color-light);
  box-shadow: var(--el-box-shadow-light);
  opacity: 0;
  transform: translateY(-100%);
  transition: all 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  position: relative;
  z-index: 100;
}

.nav-container.show {
  opacity: 1;
  transform: translateY(0);
}

.nav-menu {
  height: 60px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-section {
  display: flex;
  align-items: center;
  min-width: 200px;
}

.right-section {
  display: flex;
  align-items: center;
}

.logo {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  margin-right: 10px;
  object-fit: cover;
}

.app-name {
  font-size: 18px;
  font-weight: 500;
  color: var(--el-text-color-primary);
  white-space: nowrap;
}

.logout-button {
  font-size: 15px;
  height: 60px;
  color: var(--el-text-color-primary);
  padding: 0 20px;
}

.logout-button:hover {
  color: var(--el-color-primary);
}

.security-badge {
  display: inline-flex;
  align-items: center;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  margin-left: 8px;
  transition: all 0.3s ease;
}

.security-badge.checking {
  background-color: #fdf6ec;
  color: #e6a23c;
}

.security-badge.secure {
  background-color: #f0f9eb;
  color: #67c23a;
}

.security-badge.insecure {
  background-color: #fef0f0;
  color: #f56c6c;
}

.security-badge :deep(.el-icon) {
  font-size: 12px;
}

.security-badge.checking :deep(.el-icon) {
  animation: rotating 2s linear infinite;
}

@keyframes rotating {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 768px) {
  .nav-menu {
    padding: 0 10px;
  }

  .left-section {
    min-width: 160px;
  }

  .app-name {
    font-size: 16px;
  }

  .logo {
    width: 32px;
    height: 32px;
  }

  .logout-button {
    font-size: 14px;
    padding: 0 10px;
  }

  .security-badge {
    padding: 1px 6px;
    font-size: 11px;
  }
  
  .security-badge :deep(.el-icon) {
    font-size: 11px;
  }
}
</style>
