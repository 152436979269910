<template>
  <div class="home">
    <!-- 可以添加一个加载提示或者保持空白 -->
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'HomeView',
  setup() {
    const router = useRouter();

    onMounted(() => {
      // 直接跳转到登录页面
      router.replace('/profile');
    });
  }
});
</script>
