import axios from 'axios';
import type { AxiosInstance, InternalAxiosRequestConfig, AxiosResponse } from 'axios';
import { ElMessage } from 'element-plus';
import router from '@/router';

const request: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || '/api',
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json'
  }
});

// 请求拦截器：添加 JWT token
request.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const token = localStorage.getItem('token');
    if (token && config.headers) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
request.interceptors.response.use(
  (response: AxiosResponse) => {
    const res = response.data;
    
    // 如果是图片类型的响应，直接返回
    if (response.headers['content-type']?.includes('image')) {
      return {
        code: 200,
        data: {
          id: response.headers['captcha-id'],
          image: `data:${response.headers['content-type']};base64,${response.data}`
        }
      };
    }
    
    // 检查业务状态码
    if (res.code !== 200) {
      ElMessage.error(res.message || '请求失败');
      return Promise.reject(new Error(res.message || '请求失败'));
    }
    
    return res;
  },
  (error) => {
    // 处理 HTTP 错误
    let message = '请求失败';
    let redirectPath: string | null = null;

    if (error.response) {
      switch (error.response.status) {
        case 400:
          message = error.response.data?.message || '请求参数错误';
          break;
        case 401: {
          message = '未授权,请重新登录';
          // 保存当前URL用于登录后跳转
          redirectPath = window.location.pathname + window.location.search;
          break;
        }
        case 403:
          message = '没有权限执行此操作';
          break;
        case 500:
          message = '服务器错误';
          break;
        default:
          message = error.response.data?.message || '请求失败';
      }
    }

    ElMessage.error(message);

    // 如果需要重定向到登录页面
    if (redirectPath) {
      router.push({
        path: '/login',
        query: { redirect: redirectPath }
      });
    }

    return Promise.reject(error);
  }
);

export default request; 