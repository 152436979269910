const env = process.env.NODE_ENV;

export const config = {
  qq: {
    appId: env === 'production' ? '102070688' : '102070688', //'生产环境APPID' : '开发环境APPID'
    redirectUri: `${window.location.origin}/oauth/qq/callback`
  },
  // ... 其他配置
} as const;

// 导出类型
export type Config = typeof config; 